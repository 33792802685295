<template>
	<v-container fluid id="dashboard-container">
		<transition name="bounce" enter-active-class="bounceInUp" leave-active-class="bounceOutDown">
		<v-card class="mx-auto" outlined color="indigo darken-2" dark max-width="444" elevation="15" v-show="expand">
			<div class="text-overline text-center white--text">Summary</div>
			<v-container fluid>
				<v-row no-gutters class="d-flex justify-end">
					<v-col cols="1">
						<v-btn icon x-small @click="hideNumber = !hideNumber" color="indigo lighten-3"> <v-icon>{{hideNumber ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon> </v-btn>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" class="d-flex justify-center flex">
						<div class="difference" v-if="!fetchingData">
							<div v-show="!hideNumber">${{ showYearly ? numberFormat(yearlyDifference) : numberFormat(difference) }}</div>
							<div v-if="hideNumber">🤫</div>

							<div class="ending" v-show="!hideNumber">/ {{ showYearly ? "yearly" : "monthly" }}</div>
						</div>
						<v-progress-circular indeterminate size="75" width="10" color="white" v-if="fetchingData"></v-progress-circular>
					</v-col>
				</v-row>

				<v-card-actions>
					<v-btn class="text-overline" color="indigo darken-4" @click="showYearly = !showYearly">
						{{ showYearly ? "show monthly" : "show annual" }}</v-btn
					>
					<v-spacer></v-spacer>
					<v-btn icon @click="show = !show">
						<v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
					</v-btn>
				</v-card-actions>

				<v-expand-transition>
					<div v-show="show">
						<v-divider></v-divider>

						<v-card-text>
							<p>After calculating your income and expenses, you are saving ${{ numberFormat(difference) }} a month.</p>

							<p>Approximately ${{ numberFormat(yearlyDifference) }} a year.</p>

							<p v-if="!moreThanAMil">
								<b class="orange--text darken-4">It would take you about {{ yearsToMil }} years to save a Million.</b>
							</p>

							<!-- <p>
								You have {{ getExpenses.length }} expenses with {{ calculateBiggestExp.name }} being your biggest one at
								${{ calculateBiggestExp.amount }}/{{ calculateBiggestExp.type }}
							</p>

							<p>
								Your biggest income is {{ calculateBiggestInc.name }} at ${{ calculateBiggestInc.amount }}/{{
									calculateBiggestInc.type
								}}
							</p> -->
						</v-card-text>
					</div>
				</v-expand-transition>
			</v-container>
		</v-card>
		</transition>
	</v-container>
</template>

<script>
/* eslint-disable */
// https://blog.logrocket.com/jwt-authentication-from-scratch-vue-js-node-js/
import { mapGetters, mapState } from "vuex";
import DashboardCard from "../components/DashboardCard.vue";
import SummaryCard from "../components/SummaryCard.vue";
import helperNumberFormat from "../helpers/numberFormat"

export default {
	components: {
		DashboardCard,
		SummaryCard,
	},

	data() {
		return {
			show: false,
			showYearly: false,
			hideNumber: false,
			expand: false,
		};
	},
	methods: {
		numberFormat(num){
			return helperNumberFormat(num)
		}
	},
	mounted(){
		this.expand = true;
	},
	beforeDestroy(){
		console.log("destroyed")
		this.expand = false;
	},
	computed: {
		...mapGetters({
			getExpenses: "getExpenses",
			getTotalMonthlyExp: "getTotalMonthlyExp",
			getGrandTotalExp: "getGrandTotalExp",
			getIncomes: "income/getIncomes",
			getTotalMonthlyInc: "income/getTotalMonthlyInc",
			getGrandTotalInc: "income/getGrandTotalInc",
		}),
		...mapState({ fetchingExpenseState: "fetchingData" }),
		currentUser() {
			return this.$store.state.auth.userToken;
		},
		fetchingIncomeData() {
			return this.$store.state.income.fetchingIncomeData;
		},
		fetchingData(){
			return this.fetchingIncomeData && this.fetchingExpenseState
		},
		difference() {
			return this.getGrandTotalInc - this.getGrandTotalExp;
		},
		yearlyDifference() {
			return this.difference * 12;
		},
		moreThanAMil() {
			if (this.yearlyDifference >= 1000000) {
				return true;
			} else {
				return false;
			}
		},
		yearsToMil() {
			return Math.round(1000000 / this.yearlyDifference);
		},
		calculateBiggestExp() {
			const max = this.getExpenses.reduce((prev, current) => (prev.amount > current.amount ? prev : current));
			return max;
		},
		calculateBiggestInc() {
			const max = this.getIncomes.reduce((prev, current) => (prev.amount > current.amount ? prev : current));
			return max;
		},
	},
};
</script>
<style lang="scss">
@import "src/styles/colors.scss";

#dashboard-container{
	background-image: linear-gradient(to bottom, #141e30, #243b55);
	height: 100%;
}
.dashboard-body {
	display: flex;
	justify-content: center;
	align-items: center;
}
.dashboard-card-title {
	font-size: 1em;
}
.difference {
	width: 330px;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 25px;
	font-size: 4vh;
	background-color: $indigo-darken-4;
	margin-bottom: 25px;
}
.ending {
	font-size: 10px;
	color: white;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	height: 5vh;
}
</style>
