import axios from "axios";
import authheader from "./auth-header";
import URLService from "./url-service";

// to create API calls for getting data and or posting data
// article: https://www.bezkoder.com/jwt-vue-vuex-authentication/
// refresh token: https://www.bezkoder.com/vue-refresh-token/

class UserService {
  // Expenses
  getExpenses() {
    return axios.get(URLService.getUrl() + "/expenses", { headers: authheader() });
  }

  getExpensesTotals() {
    return axios.get(URLService.getUrl() + "/expenses/totals", { headers: authheader() })
  }

  updateExpense(expense) {
    return axios.put(URLService.getUrl() + `/expense/${expense.expenseId}`, expense, { headers: authheader() });
  }

  deleteExpense(expense) {
    return axios.delete(URLService.getUrl() + `/expense/${expense.expenseId}`, { headers: authheader() })
  }

  addExpense(expense) {
    return axios.post(URLService.getUrl() + "/expense", expense, { headers: authheader() })
  }

  // ==================================================================================

  // Incomes
  getIncomes() {
    return axios.get(URLService.getUrl() + "/incomes", { headers: authheader() });
  }
  getIncomesTotals() {
    return axios.get(URLService.getUrl() + "/incomes/totals", { headers: authheader() });
  }
  // body goes before the headers
  updateIncome(income) {
    return axios.put(URLService.getUrl() + `/income/${income.incomeId}`, income, { headers: authheader() });
  }
  deleteIncome(income) {
    return axios.delete(URLService.getUrl() + `/income/${income.incomeId}`, { headers: authheader() })
  }
  addIncome(income) {
    return axios.post(URLService.getUrl() + "/income", income, { headers: authheader() })
  }
}
export default new UserService();