import axios from "axios";
import URLService from "./url-service";

class SignupService {
	signup(signupUser) {
		return axios
			.post(URLService.getUrl() + "/signup", {
				email: signupUser.email,
				password: signupUser.password,
				confirmPassword: signupUser.confirmPassword,
				username: signupUser.username,
			})
			.then((response) => {
				if (response.data.token) {
					localStorage.setItem("signupToken", response.data.token);
				}
				return response.data;
			});
	}
}

export default new SignupService();
