import SignupService from "../services/signup-service";

const signupToken = localStorage.getItem("signupToken");

export const signup = {
  namespaced: true,
  state: {
    status: { signedUp: signupToken ? true : false },
    loadingSigning: false,
    signingError: null,
    signingToken: signupToken ? signupToken : null,
  },
  getters: {
    getSignupStatus: (state) => state.status,
    getLoadingSigning: (state) => state.loadingSigning,
    getSigningError: (state) => state.signingError,
  },
  mutations: {
    signingUser: (state) => (state.loadingSigning = true),
    signingUserStop: (state, errorMessage) => {
      state.loadingSigning = false;
      state.signingError = errorMessage;
    },
    signingSuccess(state, token) {
      state.status.signedUp = true;
      state.signingToken = token;
    },
    signingFailure(state) {
      state.status.signedUp = false;
      state.signingToken = null;
    },
  },

  actions: {
    signup({ commit }, user) {
      commit("signingUser");
      return SignupService.signup(user).then(
        (token) => {
          commit("signingUserStop");
          commit("signingSuccess", token);
          return Promise.resolve(token);
        },
        (error) => {
          commit("signingUserStop", error);
          commit("signingFailure");
          return Promise.reject(error);
        }
      );
    },
  },
};
