import axios from "axios";
import store from '../store';
import URLService from './url-service'

class AuthService {
  login(user) {
    return axios
      .post(URLService.getUrl() + "/login", {
        email: user.email,
        password: user.password,
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("accessToken", response.data.token);
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("signupToken");
    store.commit("signup/signingFailure");
    store.commit('updateCreds', {})
    store.commit('updateExpenses', null)
    store.commit('income/updateIncomes', null)
  }
}

export default new AuthService();
