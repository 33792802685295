import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Ads from "vue-google-adsense";

Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(require('vue2-animate/dist/vue2-animate.min.css'));


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");


// router authentication https://www.youtube.com/c/ScalableScripts/videos
// vuex https://www.youtube.com/watch?v=30XtkPC8nHI
// adsense: https://makeshiftinsights.com/blog/adsense-vue-js-app/