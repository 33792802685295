<template>
	<v-app id="moneyo-app">
		<v-app-bar app color="light-blue">
			<v-app-bar-nav-icon @click="drawer = !drawer" v-if="currentUser"></v-app-bar-nav-icon>
			<v-toolbar-title><div class="moneyo-title" @click="navHome">Money-O</div></v-toolbar-title>
			<v-spacer></v-spacer>
			<!-- <v-btn text rounded v-if="currentUser"> {{ username }}</v-btn>  -->

			<v-btn text rounded v-if="currentUser" class="deep-purple--text text--accent-4" @click="logout">Logout</v-btn>
		</v-app-bar>

		<v-navigation-drawer v-model="drawer" fixed temporary v-if="currentUser" dark>
			<!-- <small color="white"> Welcome {{ username }} </small> -->
			<v-list-item dark>
				<v-list-item-content>
					<v-list-item-title class="text-h6"> Welcome! </v-list-item-title>
					<v-list-item-subtitle>
						{{ username }}
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			<v-divider></v-divider>
			<v-list nav dense>
				<v-list-item-group v-model="group" active-class="deep-purple" dark>
					<v-list-item v-for="item in drawerList" :key="item.title" link @click="navigation(item.route)">
						<v-list-item-icon>
							<v-icon>{{ item.icon }}</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>
								{{ item.title }}
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>

		<v-main class="main-app">
			<router-view />
			<!-- <v-bottom-navigation v-model="value" background-color="deep-purple" dark shift>
				<v-btn>
					<span>Dashboard</span>
					<v-icon>mdi-desktop-mac-dashboard</v-icon>
				</v-btn>
				<v-btn>
					<span>Income</span>
					<v-icon>mdi-cash-plus</v-icon>
				</v-btn>
			</v-bottom-navigation> -->
		</v-main>
	</v-app>
</template>

<script>
/* eslint-disable */

export default {
	name: "App",

	data: () => ({
		drawer: null,
		username: "",
		value: 0,
		drawerList: [
			{ title: "Dashboard", icon: "mdi-desktop-mac-dashboard", route: '/' },
			{ title: "Income", icon: "mdi-cash-plus", route: '/Income' },
			{ title: "Expense", icon: "mdi-cash-minus", route: '/Expenses'},
		],
	}),
	computed: {
		currentUser() {
			this.username = this.$store.getters.getUsername;

			return this.$store.state.auth.userToken;
		},
	},
	methods: {
		logout() {
			this.$store.dispatch("auth/logout");
			this.$router.push("/login");
		},
		navHome() {
			this.$router.push("/");
		},
    navigation(route) {
      this.$router.push(route)
    }
	},
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poiret+One&display=swap");

//TODO: make html, body background black so the iphone will be able to have a black background


button > span {
	pointer-events: none;
}
.moneyo-title {
	font-family: "Poiret One", cursive;
	font-size: 1.5rem;
	font-weight: 900;
	cursor: pointer;
}
.main-app {
	/* carbon fiber background */
	/* background: linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px,
		linear-gradient(207deg, #151515 5px, transparent 5px) 10px 0px,
		linear-gradient(27deg, #222 5px, transparent 5px) 0px 10px,
		linear-gradient(207deg, #222 5px, transparent 5px) 10px 5px, linear-gradient(90deg, #1b1b1b 10px, transparent 10px),
		linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);
	background-color: #131313;
	background-size: 20px 20px; */
	// background-image: linear-gradient(to bottom, #141e30, #243b55);
	background-color: black;
}
</style>
