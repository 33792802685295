import AuthService from "../services/auth.service";

const userToken = localStorage.getItem("accessToken");

// const initialState = userToken
//   ? { status: { loggedIn: true }, userToken, loadingUser: false, userLoginError: null, userEmail: null, userPassword: null }
//   : { status: { loggedIn: false }, userToken: null, loadingUser: false, userLoginError: null, userEmail: null, userPassword: null };

export const auth = {
	namespaced: true,
	state: {
		status: {
			loggedIn: userToken ? true : false,
		},
		userToken: userToken ? userToken : null,
		loadingUser: false,
		userLoginError: null,
		userEmail: null,
		userPassword: null,
	},
	getters: {
		getStatus: (state) => state.status,
		getLoadingUser: (state) => state.loadingUser,
		getUserLoginError: (state) => state.userLoginError,
		getUserEmail: (state) => state.userEmail,
		getUserPassword: (state) => state.userPassword,
	},
	actions: {
		login({ commit }, user) {
			commit("fetchingUser");
			return AuthService.login(user).then(
				(token) => {
					commit("fetchingUserStop");
					commit("loginSuccess", token);
					return Promise.resolve(token);
				},
				(error) => {
					commit("fetchingUserStop", error);
					commit("loginFailure");
					return Promise.reject(error);
				}
			);
		},
		logout({ commit }) {
			AuthService.logout();
			commit("logout");
		},
	},
	mutations: {
		fetchingUser: (state) => (state.loadingUser = true),
		fetchingUserStop: (state, errorMessage) => {
			state.loadingUser = false;
			state.userLoginError = errorMessage;
		},
		loginSuccess(state, token) {
			state.status.loggedIn = true;
			state.userToken = token;
		},
		loginFailure(state) {
			state.status.loggedIn = false;
			state.userToken = null;
		},
		logout(state) {
			state.status.loggedIn = false;
			state.userToken = null;
		},
		fetchLoggingUserEmail(state, user) {
			state.userEmail = user;
		},
		fetchingLoggingUserPassword(state, password) {
			state.userPassword = password;
		},
	},
};
