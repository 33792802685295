<template>
	<!-- <v-card
		class="mx-auto"
		max-width="344"
		outlined
		v-ripple="{ class: ` ` }"
		color="deep-purple"
		dark
		v-if="!loading"
		@click="navigate"
	>
		<v-list-item three-line>
			<v-list-item-content>
				<div class="text-overline mb-4 text-center">{{ title }}</div>
				<v-divider color="pink"></v-divider>
				<div class="total-monthly-container d-flex justify-space-between">
					<small class="text-overline">total monthly</small>
					<h3 class="">${{ numberFormat(grandTotal) }}</h3>
				</div>
				<div class="d-flex justify-center">
					<v-icon v-if="title.includes('INCOME')">mdi-cash-plus</v-icon> <v-icon v-else>mdi-cash-minus</v-icon>
				</div>
			</v-list-item-content>
		</v-list-item>
	</v-card> -->

	<v-card v-if="!loading" class="mx-auto dashboard-card" max-width="344" min-height="200">
		<v-img src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg" height="200px"></v-img>

		<v-card-title> Top western road trips </v-card-title>

		<v-card-subtitle> 1,000 miles of wonder </v-card-subtitle>

		<v-card-actions>
			<v-btn color="orange lighten-2" text> Explore </v-btn>

			<v-spacer></v-spacer>

			<v-btn icon @click="show = !show">
				<v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
			</v-btn>
		</v-card-actions>

		<v-expand-transition>
			<div v-show="show">
				<v-divider></v-divider>

				<v-card-text>
					I'm a thing. But, like most politicians, he promised more than he could deliver. You won't have time for
					sleeping, soldier, not with all the bed making you'll be doing. Then we'll go with that data file! Hey, you
					add a one and two zeros to that or we walk! You're going to do his laundry? I've got to find a way to escape.
				</v-card-text>
			</div>
		</v-expand-transition>
	</v-card>
	<v-skeleton-loader
		v-else
		class="mx-auto"
		max-width="444"
		type="card"
		color="white"
		dark
		max-height="100"
	></v-skeleton-loader>
</template>

<script>
import helperNumberFormat from "../helpers/numberFormat";
export default {
	name: "DashboardCard",
	props: {
		loading: Boolean,
		title: String,
		grandTotal: Number,
		totalMonthly: Number,
		navigateTo: String,
	},
	methods: {
		navigate() {
			this.$router.push(this.navigateTo);
		},
		numberFormat(number) {
			return helperNumberFormat(number);
		},
	},
};
</script>

<style lang="scss">
@import "src/styles/colors.scss";
.dashboard-card {
}
.dashboard-card-heading {
	color: $moneyo-blue;
	font-size: 1vh;
}
.dashboard-card-container {
	height: 10vh;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	h3 {
		font-size: 5vh;
	}
}
</style>
