<template>
	<!-- <v-card
		class="mx-auto"
		max-width="444"
		min-height="130"
		outlined
		:color="difference > 0 ? 'green' : 'pink'"
		dark
		ripple
		v-if="!loading"
	>
		<v-list-item three-line>
			<v-list-item-content>
				<div class="text-overline mb-4 text-center">TOTAL SAVINGS</div>
				<v-divider color="pink"></v-divider>
				<div class="d-flex justify-center flex-column difference-card">
					<h3 class="text-center">${{ formattedNumber }}</h3>
				</div>
			</v-list-item-content>
		</v-list-item>
	</v-card> -->

	<!-- <v-card v-if="!loading" class="mx-auto summary-card">
		<v-card-text>
			<div class="text-overline mb-4 text-center summary-card-heading">Total Savings</div>
			<div class="summary-card-container">
				<h3>${{ formattedNumber }}</h3>
			</div>
			<div class="blue-box" :class="difference > 0 ? 'moneyo-green' : 'moneyo-pink'">
				<v-btn fab small color="deep-purple" dark class="elevation-10"><v-icon>mdi-plus</v-icon></v-btn>
			</div>
		</v-card-text>
	</v-card>
	<v-skeleton-loader
		v-else
		class="mx-auto"
		max-width="444"
		type="card"
		color="white"
		dark
		max-height="200"
	></v-skeleton-loader> -->
	<v-col md="12">
		<v-row>
			<v-col md="4" class="text-overline mb-4 text-center summary-card-heading">
				Total Savings
			</v-col>
		</v-row>
	</v-col>



</template>

<script>
export default {
	name: "SummaryCard",
	props: {
		difference: Number,
		formattedNumber: String,
		loading: Boolean,
	},
};
</script>

<style lang="scss">
@import "src/styles/colors.scss";




// .difference-card {
// 	height: 55%;
// }
// .moneyo-green {
// 	background-color: $moneyo-green;
// }
// .moneyo-pink {
// 	background-color: $moneyo-pink;
// }
// .summary-card {
// 	z-index: 2;
// 	overflow: hidden;
// }
// .blue-box {
// 	/* background-color: $moneyo-green; */
// 	height: 10vh;
// 	border-radius: 1px 50px;
// 	position: relative;
// 	bottom: -35px;
// 	right: -17px;
// 	display: flex;
// 	justify-content: right;
// 	align-items: flex-end;
// 	padding: 25px;
// 	/* border: 1px solid grey; */
// }
// .summary-card-heading {
// 	color: $moneyo-blue;
// 	font-size: 1vh;
// }
// .summary-card-container {
// 	height: 10vh;
// 	display: flex;
// 	justify-content: center;
// 	align-items: flex-end;
// 	h3 {
// 		font-size: 5vh;
// 	}
// }
</style>
