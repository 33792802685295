let local = false;

class URLService {
	getUrl() {
		return local
			? "http://localhost:5000/moneyo-backend/us-central1/api"
			: "https://us-central1-moneyo-backend.cloudfunctions.net/api";
	}
}

export default new URLService();
