import UserService from "../services/user.service";
import getRandomColor from "../helpers/getRandomColor";

export const income = {
    namespaced: true,
    state: {
        incomes: [],
        totalMonthlyInc: 0,
        totalBiweeklyInc: 0,
        totalWeeklyInc: 0,
        totalDailyInc: 0,
        grandTotalInc: 0,
        fetchingIncomeData: false,
        fetchingIncomeError: null,
    },
    getters: {
        getIncomes: (state) => {
            state.incomes.forEach(element => {
                element.backgroundColor = getRandomColor()
            })
            return state.incomes
        },
        getTotalMonthlyInc: (state) => state.totalMonthlyInc,
        getTotalBiweeklyInc: (state) => state.totalBiweeklyInc,
        getTotalWeeklyInc: (state) => state.totalWeeklyInc,
        getTotalDailyInc: (state) => state.totalDailyInc,
        getGrandTotalInc: (state) => state.grandTotalInc,
    },
    mutations: {
        fetchingIncomeStart: (state) => (state.fetchingIncomeData = true),
        fetchingIncomeStop: (state, errorMessage) => {
          state.fetchingIncomeData = false;
          state.fetchingIncomeError = errorMessage;
        },
        updateIncomes: (state, data) => {
            let isNull = data == null && true;
            state.incomes = isNull ? [] : data.incomes;
            state.totalMonthlyInc = isNull ? 0 : data.totalMonthlyIncomes;
            state.totalBiweeklyInc = isNull ? 0 : data.totalBiweekIncome;
            state.totalWeeklyInc = isNull ? 0 : data.totalWeeklyIncome;
            state.totalDailyInc = isNull ? 0 : data.totalDailyIncome;
            state.grandTotalInc = isNull ? 0 : data.grandTotalOfInc;
        },
        updateIncomesTotals: (state, data) => {
            let isNull = data == null && true;
            state.totalMonthlyInc = isNull ? 0 : data.totalMonthlyIncomes;
            state.totalBiweeklyInc = isNull ? 0 : data.totalBiweekIncome;
            state.totalWeeklyInc = isNull ? 0 : data.totalWeeklyIncome;
            state.totalDailyInc = isNull ? 0 : data.totalDailyIncome;
            state.grandTotalInc = isNull ? 0 : data.grandTotalOfInc;
        }
    },
    actions: {
        getUserIncomes({ commit }) {
            commit("fetchingIncomeStart");
            return UserService.getIncomes().then(
                (response) => {
                    commit("fetchingIncomeStop");
                    commit("updateIncomes", response.data);
                    return Promise.resolve(response);
                },
                (error) => {
                    commit("fetchingIncomeStop", error);
                    console.log(error);
                    return Promise.reject(error);
                }
            );
        },
        getUserIncomesTotals({ commit }) {
            commit("fetchingIncomeStart")
            return UserService.getIncomesTotals().then(
                (response) => {
                    commit("fetchingIncomeStop");
                    commit("updateIncomesTotals", response.data)
                    return Promise.resolve(response)
                },
                (error) => {
                    commit("fetchingIncomeStop", error);
                    console.log(error);
                    return Promise.reject(error);
                }
            )
        },
        updateUserIncome({ commit }, income) {
            commit("fetchingIncomeStart");
            return UserService.updateIncome(income).then(
                (response) => {
                    commit("fetchingIncomeStop")
                    return Promise.resolve(response)
                },
                (error) => {
                    commit("fetchingIncomeStop", error);
                    console.log(error)
                    return Promise.reject(error)
                }
            )
        },
        deleteUserIncome({ commit }, income) {
            commit("fetchingIncomeStart");
            return UserService.deleteIncome(income).then(
                (res) => {
                    commit("fetchingIncomeStop")
                    console.log(res)
                    return Promise.resolve(res)
                },
                (err) => {
                    commit("fetchingIncomeStop", err)
                    console.log(err)
                    return Promise.reject(err)
                }
            )
        },
        addUserIncome({ commit }, income) {
            commit("fetchingIncomeStart")
            return UserService.addIncome(income).then(
                (response) => {
                    commit("fetchingIncomeStop")
                    return Promise.resolve(response)
                },
                (error) => {
                    commit("fetchingIncomeStop", error);
                    console.log(error)
                    return Promise.reject(error)
                }
            )
        },
    }
}