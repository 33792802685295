import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import store from "../store";
import parseJwt from "../services/jwt-service";
import dayjs from "dayjs";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/hello",
    name: "HelloWorld",
    component: () => import("../components/HelloWorld.vue")
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import("../views/Signup.vue"),
  },
  {
    path: "/income",
    name: "Income",
    component: () => import("../views/Income.vue")
  },
  {
    path: "/expenses",
    name: "Expenses",
    component: () => import("../views/Expenses.vue")
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/signup", "/hello"];
  const authRequired = !publicPages.includes(to.path);

  let isLoggedIn = store.getters["auth/getStatus"].loggedIn;
  // refresh token
  // https://www.youtube.com/watch?v=JWeoQn6KB0o&list=PL4cUxeGkcC9jUPIes_B8vRjn1_GaplOPQ&index=7
  if (localStorage.accessToken) {
    const parsedToken = parseJwt(localStorage.accessToken);
    if (parsedToken.exp < dayjs().unix()) {
      store.dispatch("auth/logout");
      next("/login");
    } else {
      next();
    }
  }

  if (isLoggedIn && to.path === "/") {
    store.dispatch("getUserExpenses");
    store.dispatch('income/getUserIncomes');
  }
  if (isLoggedIn && to.path === '/Income') {
    store.dispatch('income/getUserIncomes');
  }

  if (isLoggedIn && to.path === '/Expenses') {
    store.dispatch("getUserExpenses")
  }

  if (authRequired && !isLoggedIn) {
    return next("/login");
  } else {
    next();
  }
});

export default router;
