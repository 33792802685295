import Vue from "vue";
import Vuex from "vuex";
import getRandomColor from "../helpers/getRandomColor";
//import createPersistedState from 'vuex-persistedstate';

import { auth } from "./auth.module";
import { signup } from './signup.module';
import { income } from './income.module';
import UserService from "../services/user.service";

Vue.use(Vuex);

// axios.defaults.baseURL = "http://localhost:5000/moneyo-backend/us-central1/api";

// axios.interceptors.request.use(function (config) {
//   const token = localStorage.getItem('accessToken')
//   config.headers.Authorization = token ? `Bearer ${token}` : '';
//   return config;
// })

export default new Vuex.Store({
  // plugins: [createPersistedState({
  //   storage: window.sessionStorage
  // })],
  state: {
    credentials: "",
    fetchingData: false,
    fetchingError: null,
    //expenses
    expenses: [],
    totalMonthlyExp: 0,
    totalBiweeklyExp: 0,
    totalWeeklyExp: 0,
    totalDailyExp: 0,
    grandTotalExp: 0,
  },
  getters: {
    getUsername: (state) => state.credentials.username,
    // expenses
    getExpenses: (state) => {
      state.expenses.forEach(element => {
        element.backgroundColor = getRandomColor()
      })
      return state.expenses
    },
    getTotalMonthlyExp: (state) => state.totalMonthlyExp,
    getTotalBiweeklyExp: (state) => state.totalBiweeklyExp,
    getTotalWeeklyExp: (state) => state.totalWeeklyExp,
    getTotalDailyExp: (state) => state.totalDailyExp,
    getGrandTotalExp: (state) => state.grandTotalExp,


  },
  mutations: {
    updateCreds: (state, credentials) => {
      state.credentials = credentials;
    },
    fetchingStart: (state) => (state.fetchingData = true),
    fetchingStop: (state, errorMessage) => {
      state.fetchingData = false;
      state.fetchingError = errorMessage;
    },
    updateExpenses: (state, data) => {
      let isNull = data == null && true;
      state.expenses = isNull ? [] : data.expenses;
      state.totalMonthlyExp = isNull ? 0 : data.totalMonthlyExpenses;
      state.totalBiweeklyExp = isNull ? 0 : data.totalBiweeklyExpenses;
      state.totalWeeklyExp = isNull ? 0 : data.totalWeeklyExpenses;
      state.totalDailyExp = isNull ? 0 : data.totalDailyExpenses;
      state.grandTotalExp = isNull ? 0 : data.grandTotalOfExp;
    },
    updateExpensesTotals: (state, data) => {
      let isNull = data == null && true;
      state.totalMonthlyExp = isNull ? 0 : data.totalMonthlyExpenses;
      state.totalBiweeklyExp = isNull ? 0 : data.totalBiweeklyExpenses;
      state.totalWeeklyExp = isNull ? 0 : data.totalWeeklyExpenses;
      state.totalDailyExp = isNull ? 0 : data.totalDailyExpenses;
      state.grandTotalExp = isNull ? 0 : data.grandTotalOfExp;
    }
  },
  actions: {
    getUserExpenses({ commit }) {
      commit("fetchingStart");
      return UserService.getExpenses().then(
        (response) => {
          commit("fetchingStop");
          commit("updateCreds", response.data.credentials);
          commit("updateExpenses", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("fetchingStop", error);
          console.log(error);
          return Promise.reject(error);
        }
      );
    },
    getUserExpensesTotals({ commit }) {
      commit("fetchingStart")
      return UserService.getExpensesTotals().then(
        (response) => {
          commit("fetchingStop");
          commit("updateExpensesTotals", response.data)
          return Promise.resolve(response)
        },
        (error) => {
          commit("fetchingStop", error);
          console.log(error)
          return Promise.reject(error)
        }
      )
    },
    updateUserExpense({ commit }, expense) {
      commit("fetchingStart")
      return UserService.updateExpense(expense).then(
        (response) => {
          commit("fetchingStop")
          return Promise.resolve(response)
        },
        (error) => {
          commit("fetchingStop", error)
          console.log(error)
          return Promise.reject(error)
        }
      )
    },
    deleteUserExpense({ commit }, expense) {
      commit("fetchingStart");
      return UserService.deleteExpense(expense).then(
        (response) => {
          commit("fetchingStop")
          return Promise.resolve(response)
        },
        (error) => {
          commit("fetchingStop", error)
          console.log(error)
          return Promise.reject(error)
        }
      )
    },
    addUserExpense({ commit }, expense) {
      commit("fetchingStart")
      return UserService.addExpense(expense).then(
        (response) => {
          commit("fetchingStop")
          return Promise.resolve(response)
        },
        (error) => {
          commit("fetchingStop", error)
          console.log(error)
          return Promise.reject(error)
        }
      )
    }
  },
  modules: {
    auth,
    signup,
    income,
  },
  // https://webdevchallenges.com/vue-js-login-component-with-spinner/
});
